/**
 * File cart-preview.js.
 *
 * Initialize offside cart preview widget.
 *
 * Author: Diego Versiani
 * Contact: https://diegoversiani.me
 *
 * * DEPENDS ON:
 * - shared/which-transition-event.js // Detect end of transition
 * - shared/getQueryString.js // Get query string values
 */

(function( $ ){

    'use strict';
  
    // Run initialize on pageload
    window.addEventListener( 'DOMContentLoaded', init );
  
    
  
    var cartOpenClass = 'cart-open',
        cartButtonSelector = '.cart-toggle',
        viewCartButtonSelector = '.added_to_cart.wc-forward',
        cartCloseButtonSelector = '.woocommerce-cart-preview-close',
        cartWrapperSelector = '.widget_shopping_cart',
        cartOverlaySelector = '.woocommerce-cart-preview__overlay',
        productImageWrapperSelector = '.single-product__images',
        siteContentSelector = '#content',
        cartButton,
        viewCartButton,
        cartCloseButton,
        cartWrapper,
        cartOverlay,
        productImageWrapper,
        siteContent,
        transitionEndEvent;
  
  
    /**
     * Initialize component and set related handlers
     */
    function init() {
      resetVariables();
  
      // Check if button and cart wrapper are available
      if ( cartButton && cartWrapper ) {
        // Add cartButton event listener
        document.addEventListener( 'click', handleCapturedClick, true );
  
        if ( window.getQueryString && window.getQueryString( 'edit-cart' ) == '1' ) {
          // Initialize cart preview open
          openCartPreview();
        }
        else {
          // Initialize cart preview as hidden
          closeCartPreview();
        }
      }
  
      // Add event listener for WooCommerce Cart Fragments
      if ( jQuery ) {
        $( document.body ).on( 'wc_fragments_loaded', function() {
          resetVariables();
          // Force cart preview to update hidden if closed
          if ( cartWrapper && !cartPreview.isOpen() ) {
            cartWrapper.style.display = 'none';
          }
        } );
      }
    };
  
  
  
    /* SEE shared/which-transition-event.js */
    function whichTransitionEvent() {
      if ( window.whichTransitionEvent ) {
        return window.whichTransitionEvent();
      }
  
      return 'transitionend';
    };
  
  
  
    function resetVariables() {
      cartButton = document.querySelector( cartButtonSelector );
      cartCloseButton = document.querySelector( cartCloseButtonSelector );
      viewCartButton = document.querySelector( viewCartButtonSelector );
      cartWrapper = document.querySelector( cartWrapperSelector );
      cartOverlay = document.querySelector( cartOverlaySelector );
      productImageWrapper = document.querySelector( productImageWrapperSelector );
      siteContent = document.querySelector( siteContentSelector );
  
      transitionEndEvent = whichTransitionEvent();
  
      // Expose functions
      var publicMethods = {};
      publicMethods.reset = resetVariables;
      publicMethods.close = closeCartPreview;
      publicMethods.open = openCartPreview;
      publicMethods.isOpen = isOpen;
      window.cartPreview = publicMethods;
    };
  
  
  
    function isOpen() {
      return document.body.classList.contains( cartOpenClass );
    };
  
  
    function toggleCartPreview() {
      if ( isOpen() ) {
        closeCartPreview();
        return;
      }
  
      openCartPreview();
    };
  
  
  
    function openCartPreview() {
      
      if ( productImageWrapper ) {
        // Stop product sticky image position update
        productImageWrapper.setAttribute( 'fixed-position', false );
  
        if ( productImageWrapper.style.position == 'fixed' ) {
          var parentPosition = productImageWrapper.parentNode.getBoundingClientRect().top * -1;
          var positionOffset = parseFloat( productImageWrapper.style.top.replace( 'px', '' ) );
  
          productImageWrapper.style.position = 'absolute';
          productImageWrapper.style.top = parentPosition + positionOffset + 'px';
        }
      }
  
      // Open cart preview
      document.body.classList.add( cartOpenClass );
      cartWrapper.style.display = 'block';
      cartWrapper.style.opacity = 1;
      cartOverlay.style.display = 'block';
    };
  
  
  
    function closeCartPreview() {
      // Close cart preview
      document.body.classList.remove( cartOpenClass );
      cartWrapper.style.opacity = 0;
      cartOverlay.style.display = 'none';
      cartWrapper.style.display = 'none'; // Close cart instantaneously as transitions are not set
  
      // Wait for close cart transition to end before enabling product sticky image position update
      if ( productImageWrapper && siteContent ) {
        siteContent.addEventListener( transitionEndEvent, function restartProductImagePositionUpdate() {
          productImageWrapper.setAttribute( 'fixed-position', true );
          siteContent.removeEventListener( transitionEndEvent, restartProductImagePositionUpdate );
        } );
      }
  
      // Hide cart preview after finish animation
      cartWrapper.addEventListener( transitionEndEvent, function hideCartPreview( e ) {
        if ( e.propertyName == 'opacity'
              && getComputedStyle(cartWrapper).getPropertyValue( 'opacity' ) == 0 ) {
          cartWrapper.style.display = 'none';
        }
  
        cartWrapper.removeEventListener( transitionEndEvent, hideCartPreview );
      } );
    };
  
  
  
    /**
     * Handle captured click for cartButton
     */
    function handleCapturedClick(e) {
      var node = e.target;
  
      // climb up the dom three
      while (node != document.body) {
  
        // handle cartButton click
        if ( node.matches( cartButtonSelector ) ) {
          e.preventDefault();
          
          // Toggle cart preview visibility
          toggleCartPreview();
  
          // Exit from handle function
          return;
        }
        else if ( node.matches( viewCartButtonSelector ) ) {
          e.preventDefault();
  
          // Open cart preview
          openCartPreview();
  
          // Exit from handle function
          return;
        }
        else if ( node.matches( cartCloseButtonSelector ) ) {
          e.preventDefault();
  
          // Close cart preview
          closeCartPreview();
  
          // Exit from handle function
          return;
        }
        else if ( node.matches( cartWrapperSelector ) ) {
          // Exit from handle function
          return;
        };
  
        node = node.parentNode;
      }
  
      // Close cart preview
      closeCartPreview();
    };
  
  })( jQuery );
  